import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';
import actions from 'actions';
import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom';
import {
  ArraySelect, InputArraySelect,
  InputKeySelect
} from 'views/pages/components/InputSelect';
import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Modal, CardTitle, CardSubtitle, CardText,
} from "reactstrap";
import { ButtonContainer, LoaderContainer, override } from "@pages/reusable-components/styled-components";
import { ClipLoader } from "react-spinners";
import usePrevious from "../../../../hooks/useprevious";
import { validateForm} from "constants/index.js";
import CustomTabs from "@pages/components/CustomTabs";
import DebounceInput from "@pages/components/DebounceInput";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import EmailsTable from "@pages/components/Table/EmailsTable";
import CalendarFilter from "@pages/components/CalendarFilter";
import CustomSearch from "@pages/components/CustomSearch";
import EmailsTab from "@pages/customers/customer/emails/emails";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out"
};

const activeStyle = {
  borderColor: "#2196f3"
};

const acceptStyle = {
  borderColor: "#00e676"
};

const rejectStyle = {
  borderColor: "#ff1744"
};

export default function CustomerEdit() {
  const { id } = useParams();
  const location = useLocation();
  const notificationAlert = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { activeMenu } = useSelector((state) => state.getState);
  const prevTab = usePrevious(activeMenu)
  const [tab, setTab] = useState('');
  const [isNotValid, setIsNotValid] = useState(false);
  const [activeTab, setActiveTab] = useState('Personal');
  const [allTabs] = useState(['Personal', 'Address', 'Finance', 'Company','Emails'])
  const [token, setToken] = useState('');
  const [businessTypes, setBusinessTypes] = useState([]);
  const [lookupAddresses, setLookupAddresses] = useState([]);
  const [emailsNextPageToken, setEmailsNextPageToken] = useState(null);
  const [emails, setEmails] = useState([]);
  const [fullMailData, setFullMailData] = useState(null);
  const [selectedGmailUserIndex, setSelectedGmailUserIndex] = useState(0);
  const [emailKeys] = useState([
    {
      key: 'From',
      label: 'From'
    },
    {
      key: 'Subject',
      label: 'Subject'
    },
    {
      key: 'Date',
      label: 'Received'
    }
  ]);

  const options = [
    {
      label: 'Read',
      value: 'read',
    },
    {
      label: 'Unread',
      value: 'unread',
    },
    {
      label: 'Starred',
      value: 'starred',
    },
    {
      label: 'Snoozed',
      value: 'snoozed',
    },
  ]

  const [companyTypes, setCompanyTypes] = useState([]);
  const [counties, setCounties] = useState([]);
  const [titles, setTitles] = useState('');
  const [showBackButton, setShowBackButton] = useState(false)
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)
  const [documents, setDocuments] = useState([]);
  const [availableDocumentsUploadTypes, setAvailableDocumentsUploadTypes] = useState('');
  const [fileLists, setFileLists] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);
  const [users, setUsers] = useState([]);

  const [confirmShow, setConfirmShow] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState('');

  const [filter, setFilter] = useState('');
  const [dateFilter, setDateFilter] = useState(null);

  const onDrop = (acceptedFiles) => {
    let newFileTypes = fileTypes;
    acceptedFiles.forEach((item) => {
      newFileTypes[item.name] = "photo_id";
    })
    setFileTypes(newFileTypes);
    setFileLists(prev => [...prev, ...acceptedFiles]);
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const [errors, setErrors] = useState('');

  const [customer, setCustomer] = useState('');
  const [managers, setManagers] = useState([]);
  const [accountManager, setAccountManager] = useState(null);

  const [loadingDocuments, setLoadingDocuments] = useState(true);

  const [loading, setLoading] = useState(true);
  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
   useEffect(()=>{
     const grs_token = Cookies.get('grs_token')
     axios({
       method: 'GET',
       url: `${constants.API_CORE_URL}/account-managers`,
       headers: {
         Authorization: `Bearer ${grs_token}`,
       },
     }).then((res)=>{
       console.log('res',res)
       setManagers(res.data.data)
     }).catch((error)=>{
       notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)

     })
   },[])
  useEffect(() => {
   if (prevTab) {
   setLoading(true);
   const grs_token = Cookies.get('grs_token')
   setToken(grs_token)

   const source = axios.CancelToken.source()

   const fetchData = async () => {
     try {
       const response = await axios({
         method: 'GET',
         url: `${constants.API_CORE_URL}/customer/${id}`,
         headers: {
           Authorization: `Bearer ${grs_token}`,
         },
         cancelToken: source.token,
       })
       const data = response.data
       let tempCustomer = {
         ...data.data,
         dob: data.data.dob ? constants.formatDate(data.data.dob) : ''
       }
       const postalCode = await axios({
         method: 'GET',
         url: `${constants.UK_VEHICLE_DATA_API_URL}/PostcodeLookup?v=2&api_nullitems=1&auth_apikey=${constants.UK_VEHICLE_DATA_API_KEY}&key_POSTCODE=${tempCustomer.postal_code}`,
       })
       if(postalCode.status === 200) {
         const data = postalCode.data
         if (data.Response) {
           if (data.Response.StatusCode === 'Success') {
             const addressList = data.Response.DataItems.AddressDetails.AddressList
             if (addressList.length === 1) {
               setSelectedAddress(addressList[0]);
             } else if (addressList.length > 1) {
               setLookupAddresses(addressList);
             }
           } else {
             setErrors({
               ...errors,
               postal_code: data.Response.StatusMessage
             })
             setLookupAddresses([])
           }
         }
       }
        setCustomer(tempCustomer);
        setAccountManager(tempCustomer?.account_manager_id)
       const companyTypesResponse = await axios({
         method: 'GET',
         url: `${constants.API_CORE_URL}/company-type`,
         headers: {
           Authorization: `Bearer ${grs_token}`,
         },
         cancelToken: source.token,
       })
       const countyResponse = await axios({
         method: 'GET',
         url: `${constants.API_CORE_URL}/county`,
         headers: {
           Authorization: `Bearer ${grs_token}`,
         },
         cancelToken: source.token,
       })
       const businessTypesResponse = await axios({
         method: 'GET',
         url: `${constants.API_CORE_URL}/business-activity`,
         headers: {
           Authorization: `Bearer ${grs_token}`,
         },
         cancelToken: source.token,
       })
       setBusinessTypes(businessTypesResponse.data.data)
       setCompanyTypes(companyTypesResponse.data.data)
       setCounties(countyResponse.data.data)
       setTitles(constants.titles);

       dispatch(actions.getNotifications(grs_token));
       setLoading(false);
     } catch (error) {
       setLoading(false);
       if (axios.isCancel(error)) {
       } else {
         notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
       }
     }
   }
   const tabs = [
     {
       value: 'info',
       key:'Customer Info',
     },
     {
       value: 'documents',
       key:'Documents Management',
     },
     {
       value: 'tasks',
       key:'Tasks',
       action: true
     },
     {
       value: 'notes',
       key:'Notes',
       action: true
     },
     {
       value: 'emails',
       key:'Emails',
       action: true
     },
   ]
   dispatch(actions.setMenusList(tabs))
   dispatch(actions.setMenuName('Customers'))

   localStorage.setItem('menuName','Customers')
   fetchData()

   if (location.state && location.state.filter) {
     setFilter(location.state.filter);
   }

   return () => {
     source.cancel()
   }
 }
  }, [prevTab])

  useEffect(() => {
    setShowBackButton(!!tab)
    if (tab === 'documents') {
      axios({
        method: 'GET',
        url: constants.API_URL + '/customer/' + id + '/documents',
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then(response => {
          if (response.status === 200) {
            let data = response.data;
            setDocuments(data.data.documents);
            setFileLists([]);
            setFileTypes([]);
            setAvailableDocumentsUploadTypes(data.data.availableDocumentsUploadTypes);
            setTimeout(() => {
              setLoadingDocuments(false);
            }, 1000);
          }
        })
        .catch(error => {
          setLoadingDocuments(false);
          notify('warning', 'Warning', error.response.statusText);
        })
    } else if( tab === 'emails' && customer.email) {
      axios({
        method: 'GET',
        url: constants.API_URL + '/get-gmail-users',
        headers: {
          Authorization: 'Bearer ' + token
        },

      })
          .then(response => {
            if (response.status === 200) {
              const tempUsers = response.data.data
              setUsers(tempUsers)
              fetchCustomerEmails(0,null,{}, tempUsers)
            }
          })
          .catch(() => {
            notify('warning', 'Warning','Something went wrong please try again later')
            setLoadingDocuments(false);
          })

    }
  }, [tab,customer.email])

  const getGmailOtherPages = async () => {
    await fetchCustomerEmails(selectedGmailUserIndex,emailsNextPageToken,dateFilter)
  }
  const fetchCustomerEmails = async (index = 0,nextToken = null,localFilter = {},localUsers = []) => {
    await setLoadingDocuments(true);
    const source = await axios.CancelToken.source()
    axios({
      method: 'GET',
      url: constants.API_URL + '/get-customer-gmail-data',
      params: {
        email: customer.email,
        userEmail: users.length ? users[index] : localUsers[index],
        nextPageToken: nextToken,
        ...localFilter,
      },
      headers: {
        Authorization: 'Bearer ' + token
      },
      cancelToken: source.token,

    })
        .then(response => {
          if (response.status === 200) {
            setEmailsNextPageToken(response.data.data.nextPageToken);
            setEmails(response.data.data.emails);
            setLoadingDocuments(false);
          }
        })
        .catch(() => {
          notify('warning', 'Warning','Something went wrong please try again later')
          setLoadingDocuments(false);
        })
  }
  const selectGmailUser = async (index) => {
    await setSelectedGmailUserIndex(index)
    await setFullMailData(null)
    await setDateFilter(null)
    await fetchCustomerEmails(index)
  }
  const getMailsDetails = (id) => {
    setLoadingDocuments(true)
    axios({
      method: 'GET',
      url: constants.API_URL + `/get-gmail-details/${id}`,
      params: {
        email: users[selectedGmailUserIndex],
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
        .then(response => {
          if (response.status === 200) {
            const data = response.data.data
            let tempData = {
              subject: '',
              from: '',
              text: ''
            }
            data.payload.headers.forEach(item=>{
              if (item.name === 'Subject') {
                tempData.subject = item.value
              } else if (item.name === 'From') {
                tempData.from = item.value
              }
            })

            let parser = new DOMParser();
            let doc = parser.parseFromString(data.snippet, "text/html");
            tempData.text =  doc.documentElement.textContent;

            setFullMailData(tempData)
            setLoadingDocuments(false)
          }
        })
        .catch(() => {
          setLoadingDocuments(false)
        })
  }

  const filterSearch = async () => {
    await fetchCustomerEmails(selectedGmailUserIndex,null,dateFilter)
  }

  useEffect(()=>{
    const required = ['full_name','email','mobile_number','driver_license_number']
    setIsNotValid(!validateForm(required,customer));
  },[customer])

  useEffect(() => {
    const enter = (ev: KeyboardEvent) => {
      if (!loadingDocuments && (ev.key === "Enter" || ev.key === "Return")) {
        filterSearch();
      }
    };
    window.addEventListener("keyup", enter, false);
    return () => {
      window.removeEventListener("keyup", enter, false);
    };
  }, [dateFilter,selectedGmailUserIndex,loadingDocuments]);

  const sendPasswordLink = () => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/customer/get-link/' + id,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        email: customer.company_name,
        id: customer.id,
      }
    })
  }
  const updateCustomer = () => {
    axios({
      method: 'PUT',
      url: constants.API_URL + '/customer/' + id,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        ...customer,
        business_activity_id: customer.business_activity_id ? customer.business_activity_id : customer.company.business_activity_id,
        company_type_id: customer.company_type_id ? customer.company_type_id : customer.company.company_type_id,
        company_name: customer.company_name ? customer.company_name : customer.company.name,
        business_address: customer.business_address ? customer.business_address : customer.company.address,
        business_phone_number: customer.business_phone_number ? customer.business_phone_number : customer.company.phone_number,
        postal_code: customer.postal_code ? customer.postal_code : undefined
      }
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setErrors('');
        if (error.response.status === 422) {
          let errors = error.response.data.errors;
          setErrors(errors);
        }
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const goList = () => {
    dispatch(actions.setMenusList([]))
    dispatch(actions.setMenuName(null))
    dispatch(actions.setActiveMenu(null))
    history.push({
      pathname: '/admin/customer',
      state: {
        filter: filter
      }
    });
  }
  const setSendType = (e, index) => {
    let newFileTypes = fileTypes;
    let key = fileLists[index].name;
    newFileTypes[key] = e.target.value;
    setFileTypes(newFileTypes);
  };

  const uploadDocument = () => {
    let formData = new FormData();
    for (let index = 0; index < fileLists.length; index++) {
      const element = fileLists[index];
      formData.append(`file[${index}]`, element);
    }
    Object.keys(fileTypes).forEach(key => {
      formData.append(`type[${key}]`, fileTypes[key]);
    })

    axios({
      method: 'POST',
      url: constants.API_URL + '/customer/' + id + '/documents',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: formData
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setDocuments(data.data);
          setFileLists([]);
          setFileTypes([]);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error);
        if (error.response && error.response.status === 422) {
          let errors = error.response.data.errors;
          setErrors(errors);
        }
        notify('warning', 'Warning', error.response && error.response.statusText ? error.response.statusText : 'Error occur.');
      })
  }

  const deleteDocument = () => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/customer/' + id + '/remove/' + selectedDocumentId,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        setConfirmShow(false);
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setDocuments(data.data);
          setSelectedDocumentId('');
          setFileLists([]);
          setFileTypes([]);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setConfirmShow(false);
        console.error('error :: ', error);
        notify('warning', 'Warning', error.response && error.response.statusText ? error.response.statusText : 'Error occur.');
      })
  }

  const viewDocument = (row) => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/customer/' + id + '/view/' + row.id,
      headers: {
        Authorization: 'Bearer ' + token
      },
      responseType: 'blob'
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          const disposition = response.request.getResponseHeader('Content-Disposition');
          let fileName = "";
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
          const blob = new Blob([data])
          const downloadUrl = URL.createObjectURL(blob)
          let a = document.createElement("a");
          a.href = downloadUrl;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        notify('warning', 'Warning', error.response && error.response.statusText ? error.response.statusText : 'Error occur.');
      })
  }

  const goSub = (subject) => {
    switch (subject) {
      case 'tasks':
        history.push({
          pathname: '/admin/customer/' + id + '/tasks',
          state: {
            filter: filter
          }
        });
        break;

      case 'notes':
        history.push({
          pathname: '/admin/customer/' + id + '/notes',
          state: {
            filter: filter
          }
        });
        break;

      default:
        break;
    }
  }

  const setSelectedAddress = (address) => {
    const a = address.FormattedAddressLines
    const addressParts = address.ComponentParts;
    let summaryAddress = address.SummaryAddress;

    if (a) {
      if(!summaryAddress) {
        summaryAddress = `${a.Street ? a.Street : ''}${a.Premises ? `, ${a.Premises}` : ''}${a.PostTown ? `, ${a.PostTown}` : ''}${a.Organisation ? `, ${a.Organisation}` : ''}${a.Locality ? `, ${a.Locality}` : ''}${a.County ? `, ${a.County}` : ''}`
      }
      setCustomer({
        ...customer,
        address: summaryAddress,
        street: addressParts.Street,
        city: a.PostTown,
        building: `${addressParts.BuildingName ? addressParts.BuildingName + (addressParts.BuildingNumber ? ',' : '') : ''}${addressParts.BuildingNumber ? ' ' + addressParts.BuildingNumber : ''}${addressParts.SubBuildingName ? ', ' + addressParts.SubBuildingName : ''}`
      })
    }
  }

  const getAddressByPostcode = (postcode) => {
    axios({
      method: 'GET',
      url: `${constants.UK_VEHICLE_DATA_API_URL}/PostcodeLookup?v=2&api_nullitems=1&auth_apikey=${constants.UK_VEHICLE_DATA_API_KEY}&key_POSTCODE=${postcode}`,
    })
      .then(response => {
        const data = response.data

        if (data.Response) {

          if (data.Response.StatusCode === 'Success') {

            const addressList = data.Response.DataItems.AddressDetails.AddressList

            if (addressList.length === 1) {
              setCustomer({
                ...customer,
                postal_code: postcode
              });

              setErrors({
                ...errors,
                postal_code: ''
              })

              setSelectedAddress(addressList[0]);
            } else if (addressList.length > 1) {
              setCustomer({
                ...customer,
                postal_code: postcode
              })

              setErrors({
                ...errors,
                postal_code: ''
              })

              setLookupAddresses(addressList);
            }
          } else {
            setErrors({
              ...errors,
              postal_code: data.Response.StatusMessage
            })
            setLookupAddresses([])
            setCustomer({
              ...customer,
              postal_code: postcode,
              address: ''
            })
          }
        }
      })
      .catch(error => {
        console.error('error :: ', error)
      })
  }
  useEffect(() => {
    if (activeMenu) {
      if (activeMenu === 'tasks' || activeMenu === 'notes') {
        goSub(activeMenu)
      }
      setTab(activeMenu)
    } else {
      dispatch(actions.setActiveMenu('info'))
      setTab('info')
    }

  }, [activeMenu])
  const saveAccountManager = () => {
    setSaveButtonDisabled(true)
    axios({
      method: 'post',
      url: `${constants.API_CORE_URL}/assign-account-manager`,
      data: {
        'customerId': customer.id,
        'managerId': accountManager,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },

    }).then((res)=>{
      setCustomer({
        ...customer,
        account_manager_id: accountManager,
      })
      setSaveButtonDisabled(false)
    }).catch((error)=>{
      console.log('error',error)
      setSaveButtonDisabled(false)
    })
  }
  return (
    loading ?
      <LoaderContainer>
        <ClipLoader
          css={override}
          size={40}
          color={`#7B61E4`}
          loading={loading}
        />
      </LoaderContainer> :
      <>
        <CardsHeader name="Customers" parentName="Customer Management" showBackButton={showBackButton} backButtonText={'Back to Customers'} redirect={goList} currentName="Edit customer" />
        <div className="rna-wrapper">
          <NotificationAlert ref={notificationAlert} />
        </div>
        <Container className="mt--5 admin-main-body" fluid >
          <Row>
            <Col xl="12">

            <Card>
              {tab === 'info' && <CardBody>
               <Row>
                 <Col sm={12} lg={5}>
                   <CustomTabs tabs={allTabs} activeTab={activeTab} setActiveTab={(e)=>{
                     setActiveTab(e)
                   }}/>
                 </Col>
                <Col sm={12} lg={5}>
                  <ArraySelect
                      label={'Account Manager'}
                      options={managers}
                      value={accountManager}
                      onChange={(e) => {
                        setAccountManager(e.target.value)
                       }
                      }
                      defaultOption={true}
                      valueKey={`id`}
                      labelKey={`name`}
                      errorMessage={'Account Manager'}
                  />
                </Col>
                 <Col lg={2}>
                     <ButtonContainer disabled={saveButtonDisabled} onClick={saveAccountManager}>
                       Save
                     </ButtonContainer>
                 </Col>
               </Row>
                {
                  activeTab === 'Personal' &&
                  <Card>
                    <CardHeader>
                      <Row className={'align-items-center justify-content-between'}>
                        <Col>
                          <h1 className="mb-0">Customer management</h1>
                        </Col>
                        <Col>
                          <Row className={'align-items-center justify-content-end'}>
                            <label className="form-control-label mr-5">Send password link</label>
                            <ButtonContainer reverse onClick={() => sendPasswordLink()}>
                              Send
                            </ButtonContainer>
                          </Row>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <div className={'mb-4'}
                           style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <div>
                          <h2>Personal information</h2>
                        </div>
                      </div>
                      <Row className={'phone-container'}>
                        <Col md={4} sm={6}>
                          <InputKeySelect
                              newLabel={`Title`}
                              options={titles}
                              value={customer && customer.title ? customer.title : ''}
                              onChange={e => setCustomer({
                                ...customer,
                                title: e.target.value,
                                attention_to: `${e.target.value ? `${titles[e.target.value]} ` : ''}${customer.first_name ? `${customer.first_name}` : ''}${customer.last_name ? ` ${customer.last_name}` : ''}`
                              })}
                              defaultOption={true}
                              invalid={errors && errors.title ? true : false}
                              errorMessage={errors.title}
                          />
                          <InputCustom
                              newLabel={'First Name'}
                              placeholder={'First Name'}
                              value={customer && customer.first_name ? customer.first_name : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                first_name: e.target.value,
                                full_name: `${e.target.value ? ` ${e.target.value}` : ''}${customer.last_name ? ` ${customer.last_name}` : ''}`,
                                attention_to: `${customer.title ? `${titles[customer.title]} ` : ''}${e.target.value ? `${e.target.value}` : ''}${customer.last_name ? ` ${customer.last_name}` : ''}`
                              })}
                          />
                          <InputCustom
                              newLabel={'Last Name'}
                              placeholder={'Last Name'}
                              value={customer && customer.last_name ? customer.last_name : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                last_name: e.target.value,
                                full_name: `${customer.first_name ? ` ${customer.first_name}` : ''}${e.target.value ? ` ${e.target.value}` : ''}`,
                                attention_to: `${customer.title ? `${titles[customer.title]} ` : ''}${customer.first_name ? `${customer.first_name}` : ''}${e.target.value ? ` ${e.target.value}` : ''}`
                              })}
                          />
                          <InputCustom
                              type={`date`}
                              newLabel={`Date of Birth`}
                              value={customer && customer.dob ? customer.dob : ''}
                              onChange={e => setCustomer({
                                ...customer,
                                dob: e.target.value
                              })}
                              invalid={errors && errors.dob ? true : false}
                              errorMessage={errors.dob}
                          />
                        </Col>
                        <Col md={4} sm={6}>
                          <InputCustom
                              newLabel={'Attention To'}
                              placeholder={'Attention To'}
                              value={customer && customer.attention_to ? customer.attention_to : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                attention_to: e.target.value
                              })}
                          />

                          <InputCustom
                              newLabel={'Email Address'}
                              checkEmail
                              required
                              checkValid={false}
                              placeholder={'Email Address'}
                              value={customer && customer.email ? customer.email : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                email: e.target.value
                              })}
                              invalid={errors && errors.email ? true : false}
                              errorMessage={'Please provide a email.'}
                          />
                          <InputCustom
                              newLabel={'Phone Number'}
                              placeholder="Phone Number"
                              type="text"
                              required
                              checkValid={false}
                              value={customer ? customer.mobile_number : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                mobile_number: e.target.value
                              })}
                              invalid={errors && errors.mobile_number ? true : false}
                              errorMessage={'Please provide a phone number.'}
                          />
                          <InputCustom
                              newLabel={'Bank account number'}
                              placeholder={'Bank account number'}
                              value={customer && customer.bank_account_number ? customer.bank_account_number : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                bank_account_number: e.target.value
                              })}
                          />
                        </Col>
                        <Col md={4} sm={6}>
                          <InputCustom
                              newLabel={'Full Name'}
                              required
                              checkValid={false}
                              placeholder="Full Name"
                              type="text"
                              value={customer ? customer.full_name : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                full_name: e.target.value
                              })}
                              invalid={errors && errors.full_name ? true : false}
                              errorMessage={'The full name field is required.'}
                          />
                          <InputCustom
                              newLabel={'N.I Number'}
                              placeholder={'N.I Number'}
                              value={customer && customer.national_insurance_number ? customer.national_insurance_number : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                national_insurance_number: e.target.value
                              })}
                          />
                          <InputCustom
                              newLabel={'D/L Number'}
                              placeholder="D/L Number"
                              type="text"
                              required
                              checkValid={false}
                              value={customer ? customer.driver_license_number : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                driver_license_number: e.target.value
                              })}
                              invalid={errors && errors.driver_license_number ? true : false}
                              errorMessage={'Please provide a D/L Number.'}
                          />
                        </Col>

                        <Col sm={12} className="mt-3 mb-4">
                          <h2>Driving licence details</h2>
                        </Col>
                        <Col sm={12} className="mt-3 mb-4">
                          <Row>
                            <Col md={3} sm={6}>
                              <FormGroup className={'d-flex align-items-center'}>
                                <label className="form-control-label w-50">D/L Number</label>
                                <Input
                                    placeholder="D/L Number"
                                    type="text"
                                    value={customer ? customer.driver_license_number : ''}
                                    onChange={(e) => setCustomer({
                                      ...customer,
                                      driver_license_number: e.target.value
                                    })}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={3} sm={6}>
                              <FormGroup className={'d-flex align-items-center'}>
                                <label className="form-control-label w-50">Country of issue</label>
                                <Input
                                    placeholder="Country of issue"
                                    type="text"
                                    defaultValue={customer ? customer.country_of_issue : ''}
                                    onChange={(e) => setCustomer({
                                      ...customer,
                                      country_of_issue: e.target.value
                                    })}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={3} sm={6}>
                              <InputCustom
                                  type={`date`}
                                  newLabel={`Licence expiry date`}
                                  value={customer && customer.licence_expiry_date ? customer.licence_expiry_date : ''}
                                  onChange={e => setCustomer({
                                    ...customer,
                                    licence_expiry_date: e.target.value
                                  })}
                                  invalid={errors && errors.licence_expiry_date ? true : false}
                                  errorMessage={errors.licence_expiry_date}
                              />
                            </Col>
                            <Col md={3} sm={6}>
                              <InputCustom
                                  type={`date`}
                                  newLabel={`Licence issue date`}
                                  value={customer && customer.licence_issue_date ? customer.licence_issue_date : ''}
                                  onChange={e => setCustomer({
                                    ...customer,
                                    licence_issue_date: e.target.value
                                  })}
                                  invalid={errors && errors.licence_issue_date ? true : false}
                                  errorMessage={errors.licence_issue_date}
                              />
                            </Col>
                          </Row>
                        </Col>

                      </Row>

                    </CardBody>
                  </Card>
                }
                {activeTab === 'Address' &&
                <Card>
                  <CardHeader>
                    <h1 className="mb-0">Address details</h1>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={4} sm={6}>
                        <DebounceInput
                            label={'Postal Code'}
                            defaultValue={customer ? customer.postal_code : ''}
                            placeholder={'Postal Code'}
                            onChange={(e) => {
                                getAddressByPostcode(e.target.value)
                            }}
                        />
                        <InputCustom
                            newLabel={'Address'}
                            placeholder="Address"
                            type="text"
                            required
                            checkValid={false}
                            value={customer ? customer.address : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              address: e.target.value
                            })}
                            invalid={errors && errors.address ? true : false}
                            errorMessage={'Address field is required.'}
                        />
                        <InputCustom
                            newLabel={'Street'}
                            placeholder={'Street'}
                            value={customer && customer.street ? customer.street : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              street: e.target.value
                            })}
                        />

                        <InputCustom
                            newLabel={`Previous Address`}
                            value={customer && customer.previous_address ? customer.previous_address : ''}
                            onChange={e => setCustomer({
                              ...customer,
                              previous_address: e.target.value
                            })}
                            invalid={errors && errors.previous_address ? true : false}
                            errorMessage={errors.previous_address}
                        />
                      </Col>
                      <Col md={4} sm={6}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Correspondent address</label>
                          <Input
                              placeholder="Select the correspondent address"
                              type="select"
                              disabled={!lookupAddresses.length}
                              onChange={(e) => {
                                let selectedAddress = lookupAddresses.find(item => item.Udprn?.toString() === e.target.value?.toString());
                                setSelectedAddress(selectedAddress);
                              }}
                          >
                            {lookupAddresses.length === 0 && <option>No lookup address to select from</option>}
                            {lookupAddresses.length > 0 &&
                            <>
                              <option>-</option>
                              {lookupAddresses.map((item, index) => {
                                return <option value={item.Udprn} key={index}>{item.SummaryAddress}</option>;
                              })}
                            </>
                            }
                          </Input>
                        </FormGroup>
                        <InputCustom
                            newLabel={'Alternate Number'}
                            placeholder={'Alternate Number'}
                            value={customer && customer.land_line_number ? customer.land_line_number : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              land_line_number: e.target.value
                            })}
                        />

                        <InputCustom
                            newLabel={'City'}
                            placeholder={'City'}
                            value={customer && customer.city ? customer.city : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              city: e.target.value
                            })}
                        />

                        <InputKeySelect
                            newLabel={`Time at Address`}
                            options={constants.timeAtAddressType}
                            value={customer && customer.time_at_address ? customer.time_at_address : ''}
                            onChange={e => setCustomer({
                              ...customer,
                              time_at_address: e.target.value
                            })}
                            defaultOption={true}
                            invalid={errors && errors.time_at_address ? true : false}
                            errorMessage={errors.time_at_address}
                        />
                      </Col>
                      <Col md={4} sm={6}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50"
                                 htmlFor="detail_is_active"
                          >
                            Is active
                          </label>
                          <Row>
                            <Col>
                              <label className="custom-toggle">
                                <input
                                    type="checkbox"
                                    checked={customer && customer.is_active === 1 ? true : false}
                                    onChange={() =>
                                        setCustomer({
                                          ...customer,
                                          is_active: customer.is_active === 1 ? 0 : 1
                                        })
                                    }
                                />
                                <span
                                    className="custom-toggle-slider rounded-circle"
                                    data-label-off="No"
                                    data-label-on="Yes"
                                />
                              </label>
                            </Col>
                          </Row>
                        </FormGroup>
                        <InputCustom
                            newLabel={'Building'}
                            placeholder={'Building'}
                            value={customer && customer.building ? customer.building : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              building: e.target.value
                            })}
                        />
                        <ArraySelect
                            label={`Country`}
                            options={counties}
                            value={customer ? customer.county_id : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              county_id: e.target.value
                            })}
                            invalid={errors && errors.county_id ? true : false}
                            defaultOption={true}
                            valueKey={`id`}
                            labelKey={`name`}
                            errorMessage={' Please provide a county.'}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                }
                {activeTab === 'Finance' &&
                <Card>
                  <CardHeader>
                    <h1 className="mb-0">Financial Information</h1>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={4} sm={12}>
                        <InputCustom
                            newLabel={'Bank name'}
                            placeholder={'Bank name'}
                            value={customer && customer.bank_name ? customer.bank_name : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              bank_name: e.target.value
                            })}
                        />
                        <InputCustom
                            newLabel={'Bank sort code'}
                            placeholder={'Bank sort code'}
                            value={customer && customer.bank_sort_code ? customer.bank_sort_code : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              bank_sort_code: e.target.value
                            })}
                        />
                      </Col>

                      <Col md={4} sm={12}>
                        <InputCustom
                            newLabel={'Bank account name'}
                            placeholder={'Bank account name'}
                            value={customer && customer.bank_account_name ? customer.bank_account_name : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              bank_account_name: e.target.value
                            })}
                        />
                        <InputCustom
                            newLabel={'Bank account number'}
                            placeholder={'Bank account number'}
                            value={customer && customer.bank_account_number ? customer.bank_account_number : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              bank_account_number: e.target.value
                            })}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                }
                {activeTab === 'Company' &&
                <Card>
                  <CardHeader>
                    <h1 className="mb-0">Company Information</h1>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={4} sm={12}>
                        <ArraySelect
                            label={`Business type`}
                            options={businessTypes}
                            value={customer?.business_activity_id ? customer?.business_activity_id : customer.company.business_activity_id}
                            onChange={(e) => setCustomer({
                              ...customer,
                              business_activity_id: e.target.value
                            })}
                            invalid={errors && errors.business_activity_id ? true : false}
                            defaultOption={true}
                            valueKey={`id`}
                            labelKey={`name`}
                            errorMessage={'Please provide a business type.'}
                        />

                        <InputCustom
                            newLabel={'Business address'}
                            placeholder={'Business address'}
                            value={customer && customer.company && customer.company.address ? customer.company.address : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              business_address: e.target.value,
                              company: {
                                ...customer.company,
                                address: e.target.value
                              }
                            })}
                            invalid={errors && errors.business_address ? true : false}
                            errorMessage={'Please provide a business address.'}
                        />
                        <InputCustom
                            newLabel={'Business phone number'}
                            placeholder={'Business phone number'}
                            value={customer && customer.company && customer.company.phone_number ? customer.company.phone_number : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              business_phone_number: e.target.value,
                              company: {
                                ...customer.company,
                                phone_number: e.target.value
                              }
                            })}
                            invalid={errors && errors.business_phone_number ? true : false}
                            errorMessage={'Please provide a business phone number.'}
                        />
                      </Col>
                      <Col md={4} sm={12}>
                        <ArraySelect
                            label={`Company type`}
                            options={companyTypes}
                            value={customer?.company_type_id ? customer?.company_type_id : customer.company.company_type_id}
                            onChange={(e) => setCustomer({
                              ...customer,
                              company_type_id: e.target.value
                            })}
                            defaultOption={true}
                            valueKey={`id`}
                            labelKey={`name`}
                            invalid={errors && errors.company_type_id ? true : false}
                            errorMessage={'Please provide a company type.'}
                        />
                        <InputCustom
                            newLabel={`Company name`}
                            required
                            checkValid={false}
                            placeholder={`Company name`}
                            value={customer && customer.company && customer.company.name ? customer.company.name : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              company_name: e.target.value,
                              company: {
                                ...customer.company,
                                name: e.target.value
                              }
                            })}
                            invalid={errors && errors.company_name ? true : false}
                            errorMessage={'Please provide a company name.'}
                        />
                      </Col>

                      <Col md={4} sm={12}>
                        <InputCustom
                            newLabel={'Insurance Company'}
                            placeholder={'Insurance Company'}
                            value={customer && customer.insurance_company ? customer.insurance_company : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              insurance_company: e.target.value
                            })}
                            invalid={errors && errors.insurance_company ? true : false}
                            errorMessage={'Please provide a Insurance company.'}
                        />
                        <InputCustom
                            newLabel={'Insurance Excess'}
                            placeholder={'Insurance Excess'}
                            value={customer && customer.insurance_excess ? customer.insurance_excess : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              insurance_excess: e.target.value
                            })}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                }
                {
                  activeTab === 'Emails' &&
                    <EmailsTab
                       customerId={customer.id}
                     />
                }
                <div className="d-flex flex-row justify-content-end">
                  <ButtonContainer onClick={() => goList(true)}>Cancel</ButtonContainer>
                  <ButtonContainer disabled={isNotValid} reverse onClick={() => updateCustomer()}>Save</ButtonContainer>
                </div>
              </CardBody>}
              {tab === 'documents' && <CardBody>
                <div className="container">
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p>Select or drop the documents, then match each of them to the right type</p>
                  </div>
                  <aside>
                    {fileLists.map((item, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: '1px solid #CCC',
                            alignItems: 'center',
                            padding: 20,
                          }}
                          key={index}
                        >
                          <Col md={3}>
                            <span>{item.name}</span>
                          </Col>
                          <Col md={3}>
                            <span>{`${item.size} bytes`}</span>
                          </Col>
                          <Col md={2}>
                            {`Document type`}
                          </Col>
                          <Col md={4}>
                            <Input
                              type='select'
                              id={`select_${index}`}
                              onChange={(e) => setSendType(e, index)}
                            >
                              {Object.keys(availableDocumentsUploadTypes).map((key, index) => {
                                return (
                                  <option value={key} key={key}>
                                    {availableDocumentsUploadTypes[key]}
                                  </option>
                                );
                              })}
                            </Input>
                          </Col>
                        </div>
                      )
                    })}
                  </aside>
                </div>
                <div className="d-flex flex-row justify-content-end mt-3">
                  <ButtonContainer onClick={() => goList(true)}>Cancel</ButtonContainer>
                  <ButtonContainer reverse onClick={() => uploadDocument()}>Save</ButtonContainer>
                </div>
                <TableCustom
                  loading={loadingDocuments}
                  items={documents}
                  tableHeaderStyle={'header-text'}
                  keyField={`id`}
                  searchField={true}
                  columns={[
                    {
                      dataField: "document_name",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "document_type",
                      text: "Type",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return availableDocumentsUploadTypes[row.category];
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <IcoMoon
                                className={'cursor-pointer'}
                                iconSet={IconSet}
                                icon={'delete_outline'}
                                size={24}
                                onClick={e => {
                                  setConfirmShow(true);
                                  setSelectedDocumentId(row.id);
                                }}
                            />
                            <IcoMoon
                                className={'cursor-pointer'}
                                iconSet={IconSet}
                                icon={'visibility'}
                                size={24}
                                onClick={() => viewDocument(row)}
                            />
                          </>
                        )
                      }
                    }
                  ]}
                />
                <Modal
                  isOpen={confirmShow}
                  toggle={() => setConfirmShow(false)}
                  className="modal-dialog-centered modal-secondary"
                >
                  <div className="modal-body">
                    {`Are you sure you want to delete this document?`}
                  </div>
                  <div className="modal-footer">
                    <ButtonContainer
                      reverse
                      onClick={() => deleteDocument()}
                    >
                      {`Delete`}
                    </ButtonContainer>
                    <ButtonContainer
                      onClick={() => setConfirmShow(false)}
                    >
                      Cancel
                    </ButtonContainer>
                  </div>
                </Modal>
              </CardBody>}
              {tab === 'emails' &&
              <Card>

                <CardHeader>
                  <Row>
                    <Col lg={2} sm={10} className={'p-2'}>
                      <h1 className="mb-0">Emails</h1>
                    </Col>
                    <Col lg={3} sm={10} className={'p-2'} >
                      <CustomSearch
                          withoutDaley={true}
                          onChange={(e) => {
                            setDateFilter({...dateFilter,search: e.target.value})}
                          }
                      />
                    </Col>
                    <Col lg={2} sm={10} className={'p-2'}>
                      <InputArraySelect
                          fullWidth
                          defaultOptionText={'Select status'}
                          onChange={(e) => {
                            setDateFilter({...dateFilter,status: e.target.value})
                          }}
                          withBorder
                          options={options}
                          labelKey={'label'}
                          valueKey={'value'}
                          defaultOption={true}
                      />
                    </Col>
                    <Col lg={2} className={'p-2'}>
                      <CalendarFilter filterByDate={(data) => {
                        setDateFilter({...dateFilter,...data})
                      }} />
                    </Col>
                    <Col lg={2} className={'p-2'}>
                      <ButtonContainer disabled={loadingDocuments} onClick={()=> !loadingDocuments && filterSearch()}>
                        Search
                      </ButtonContainer>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg={3}>
                      {
                        users.length > 0 &&
                        <table className={'table table-bordered'}>
                          <thead>
                          <tr  className={'reports-table-header'}>
                            <th className={'header-text'} >
                              Users
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          {users.map((item,index)=>{
                            return <tr key={'tr_'+index} className={`multicolor ${index === selectedGmailUserIndex && 'even'}`}>
                              <td onClick={()=>selectGmailUser(index)} className={'cursor-pointer'} key={index}>
                                {item}
                              </td>
                            </tr>
                          })}
                          </tbody>

                        </table>
                      }

                    </Col>
                    <Col lg={9}>
                      {fullMailData  ? <Container>
                            <Row>
                              <Col className={'p-3'}>
                                <h2>
                                  Gmail
                                </h2>
                              </Col>
                              <Col className={'p-3'}>
                                <ButtonContainer reverse onClick={() => setFullMailData(null)}>Back to the list</ButtonContainer>
                              </Col>

                            </Row>
                            <Card>
                              <CardBody>
                                <CardTitle>
                                  From - {fullMailData.from}
                                </CardTitle>
                                <CardSubtitle>
                                  {fullMailData.subject}
                                </CardSubtitle>
                                <CardText>
                                  {fullMailData.text}
                                </CardText>
                              </CardBody>
                            </Card>
                          </Container> :
                      <EmailsTable loading={loadingDocuments}
                                   searchValue={dateFilter?.search}
                                   nextPageToken={emailsNextPageToken}
                                   paginate={getGmailOtherPages}
                                   goToDetails={getMailsDetails}
                                   tableData={emails}
                                   keys={emailKeys}/>
                      }

                    </Col>
                  </Row>
                </CardBody>
              </Card>
              }

            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}