import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import NotificationAlert from 'react-notification-alert';
import axios from 'axios';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import DetailsModal from './detailsModal';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as taskActions from '@redux/Task/actions';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/core';
import actions from "../../../actions";
import CustomTabs from "@pages/components/CustomTabs";
import { parseToKey } from "constants/index.js";
import Cookies from "js-cookie";
import {ArraySelect} from "@pages/components/InputSelect";
import {ButtonContainer} from "@pages/reusable-components/styled-components";

const tabs = [
  {
    name: 'My Tasks',
    slug: ""
  },
  {
    name: 'MOT Check',
    slug: "mot-check"
  },
  {
    name: 'MID Check',
    slug: "mid-check"
  },
  {
    name: 'RFL Check',
    slug: "rfl-check"
  },

  {
    name: 'Excess Mileage',
    slug: "mileage-exceeded"
  },
  {
    name: 'Deal Expiry',
    slug: "return-of-van"
  },
  {
    name: 'Warranty',
    slug: "warranty-check"
  }
];

export default function Tasks() {
  const notificationAlert = useRef(null);
  const inputTitleEl = useRef(null);
  const inputDescriptionEl = useRef(null);

  const { task_category_slug } = useParams();
  const profileData = useSelector((state) => state.getState).profileData;

  const taskState = useSelector((state) => state.taskState, shallowEqual);
  const { isUpdateTaskSuccess, searchTaskSuccess } = useSelector(
    (state) => state.taskState,
    shallowEqual
  );

  const dispatch = useDispatch();
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [statuses] = useState([]);
  const [resource] = useState('');
  const [customDealerMembers, setCustomDealerMembers] = useState([]);
  const [highestColumnItems, setHighestColumnItems] = useState(0);
  const [selectedAccountManager, setSelectedAccountManager] = useState(null);
  const [mobileSize] = useState(660);

  const [taskModal, setTaskModal] = useState({
    show: false,
    header: '',
    division: '',
  });

  const [selectedTask, setSelectedTask] = useState('');
  const [activeTab, setActiveTab] = useState('')
  const [columns, setColumns] = useState({});
  const [columnsLength, setColumnsLength] = useState(0);
  const [titleEditMode, setTitleEditMode] = useState(false);
  const [managers, setManagers] = useState([]);
  const [descriptionEditMode, setDescriptionEditMode] = useState(false);
  const [isSearched, setIsSearched] = useState(false);

  const notify = (type, header, message) => {
    const options = {
      place: 'tc',
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {' '}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 5,
    };
    notificationAlert.current.notificationAlert(options);
  };
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: blue;
  `;

  useEffect(() => {
    if (isUpdateTaskSuccess) {
      setIsSearched(!isSearched);
      setLoading(false);
      fetchData({ type: activeTab,account_manager_id  :selectedAccountManager});
    }
  }, [isUpdateTaskSuccess]);

  useEffect(() => {
    if (searchTaskSuccess) {
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  }, [searchTaskSuccess]);

  useEffect(() => {
    const grs_token = Cookies.get('grs_token');
    setToken(grs_token)

    axios({
      method: 'GET',
      url: `${constants.API_CORE_URL}/account-managers`,
      headers: {
        Authorization: `Bearer ${grs_token}`,
      },
    }).then((res)=>{
      let tempManagers = res.data.data
      let manager = tempManagers.find(item=> item.id === profileData.id)
      let id = manager ? manager.id : null
      setSelectedAccountManager(id)
      fetchData({ type: '',account_manager_id: id })
      setManagers(tempManagers)
    }).catch((error)=>{
      notify('warning', 'Warning', 'Something went wrong, please try again later')
    })
  }, []);

  const fetchData = (filter = {}) => {

    dispatch({ type: taskActions.GET_TASKS_REQUEST, data: filter, });

  }
  useEffect(() => {
    const tasks = [...taskState.tasks];
    const statuses = constants.taskStatuses;

    let index = 0;
    if (Object.keys(statuses)?.length) {
      let obj = {};
      let columnItems = 0;
      Object.keys(statuses).forEach((sta) => {
        let tasksObj = [];
        columnItems = columnItems >= tasks.length ? columnItems : tasks.length;
        tasks.forEach((task) => {
          if (task?.status === sta) {
            tasksObj.push({
              ...task,
              id: task.id.toString(),
              index: task?.order_number !== null ? task.order_number : index,
            });
            index++;
          }
        });

        obj[`[${sta}]`] = {
          name: statuses[sta],
          items: tasksObj.sort((a, b) => a['index'] - b['index']),
        };
      });
      setHighestColumnItems(columnItems);
      setColumns(obj);
      setColumnsLength(Object.keys(statuses).length);

      const dealerMembers = [];
      taskState.dealerMembers.forEach(({ name, id }) => {
        dealerMembers.push({ value: id, label: name, id });
      });
      setCustomDealerMembers(dealerMembers);
    }

  }, [taskState]);

  useEffect(() => {
    if (titleEditMode) {
      inputTitleEl.current.focus();
    }

    if (descriptionEditMode) {
      inputDescriptionEl.current.focus();
    }
  }, [descriptionEditMode, titleEditMode]);

  const setMenus = (name) => {
    dispatch(actions.setMenuName(name))
    localStorage.setItem('menuName', name)
  }
  const taskEdit = (task) => {
    let url;
    switch (task.taskable_type) {
      case 'deal':
        setMenus('Deals')
        url = `/admin/deals/${task.taskable_id}/edit`;
        break;
      case 'customer':
      case 'App\\Models\\Customer':
        setMenus('Customers')
        url = `/admin/customer/${task.taskable_id}/edit`;
        break;
      case 'lead':
        setMenus('Leads')
        url = `/admin/lead/${task.taskable_id}/edit`;
        break;
      case 'vehicle':
      case 'App\\Models\\Vehicle':
        setMenus('Vehicles')
        url = `/admin/vehicle/${task.taskable_id}/edit`;
        break;
      default:
        break;
    }
    const origin = window.location.origin
    window.open(origin+url)
  };

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (
      Object.values(constants.taskStatuses).indexOf(
        columns[destination.droppableId].name
      ) === -1
    ) {
      notify('warning', 'Warning', "You can't move in this column");
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      updateTask({
        id: removed.id,
        status: destination.droppableId.replace(/[[\]']+/g, ''),
        index: destination.index,
      });
      if (resource === 'lead') {
        axios({
          method: 'PUT',
          url: `${constants.API_URL}/leads/${removed.taskable_id}/tasks/${removed.uuid}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            ...removed,
            due_date:
              removed && removed.due_date
                ? constants.formatDate(removed.due_date)
                : null,
            task_assignee:
              removed && removed.taskAssignee ? removed.taskAssignee : null,
            task_category: task_category_slug,
            status: destination.droppableId.replace(/[\[\]']+/g, ''),
          },
        })
          .then((response) => {
            setSelectedTask('');
            setTaskModal({
              ...taskModal,
              show: false,
            });
            if (response.status === 200) {
              let data = response.data;
              console.log('response data :: ', data);

              const _tasks = data.data;

              let obj = {};

              Object.keys(statuses).forEach((sta) => {
                let _tasksObj = [];

                _tasks.forEach((task) => {
                  if (task.status === sta) {
                    _tasksObj.push({
                      ...task,
                      id: task.id.toString(),
                    });
                  }
                });

                obj[`[${sta}]`] = {
                  name: statuses[sta],
                  items: _tasksObj,
                };
              });

              setColumns(obj);

              notify('success', 'Success', data.message);
            }
          })
          .catch((error) => {
            notify('warning', 'Warning', error.response.statusText);
            console.error('error :: ', error.response);
            if (error.response && error.response.status === 422) {
              return;
            }
            setTaskModal({
              ...taskModal,
              show: false,
            });
          });
      } else if (resource === 'vehicle') {
        axios({
          method: 'PUT',
          url: `${constants.API_URL}/vehicle/${removed.taskable_id}/task/${removed.uuid}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            ...removed,
            task_assignee: removed.taskAssignee,
            due_date:
              removed && removed.due_date
                ? constants.formatDate(removed.due_date)
                : null,
            task_category: task_category_slug,
            status: destination.droppableId.replace(/[\[\]']+/g, ''),
            division: 'change_status',
          },
        })
          .then((response) => {
            setSelectedTask('');
            setTaskModal({
              ...taskModal,
              show: false,
            });
            if (response.status === 200) {
              const data = response.data;
              console.log('response data :: ', data);

              const _tasks = data.data.tasks;

              let obj = {};

              Object.keys(statuses).forEach((sta) => {
                let _tasksObj = [];

                _tasks.forEach((task) => {
                  if (task.status === sta) {
                    _tasksObj.push({
                      ...task,
                      id: task.id.toString(),
                    });
                  }
                });

                obj[`[${sta}]`] = {
                  name: statuses[sta],
                  items: _tasksObj,
                };
              });

              setColumns(obj);
              notify('success', 'Success', data.message);
            }
          })
          .catch((error) => {
            notify(
                'warning',
                'Warning',
                error.response && error.response.statusText
                    ? error.response.statusText
                    : 'Error occur.'
            );
            console.error('error :: ', error.response);
            if (error.response && error.response.status === 422) {
              return;
            }
            setTaskModal({
              ...taskModal,
              show: false,
            });

          });
      } else if (resource === 'deal') {
        console.log(
          'destination :: ',
          destination.droppableId.replace(/[\[\]']+/g, '')
        );
        axios({
          method: 'PUT',
          url: `${constants.API_URL}/deal/${removed.taskable_id}/task/${removed.uuid}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            ...removed,
            assignee: removed.taskAssignee,
            task_title: removed.title,
            task_comments: removed.comments,
            // task_status: removed.status,
            task_due_date: constants.formatDate(removed.due_date),
            task_category: task_category_slug,
            task_status: destination.droppableId.replace(/[\[\]']+/g, ''),
            division: 'change_status',
          },
        })
          .then((response) => {
            if (response.status === 200) {
              let data = response.data;
              console.log('response data :: ', data);

              const _tasks = data.data.tasks;

              let obj = {};

              Object.keys(statuses).forEach((sta) => {
                let _tasksObj = [];

                _tasks.forEach((task) => {
                  if (task.status === sta) {
                    _tasksObj.push({
                      ...task,
                      id: task.id.toString(),
                    });
                  }
                });

                obj[`[${sta}]`] = {
                  name: statuses[sta],
                  items: _tasksObj,
                };
              });

              setColumns(obj);
              notify('success', 'Success', data.message);
            }
          })
          .catch((error) => {
            notify(
                'error',
                'Error',
                error.response && error.response.statusText
                    ? error.response.statusText
                    : 'Error occur.'
            );
            console.error('error :: ', error.response);
            if (error.response && error.response.status === 422) {
              return;
            }

          });
      } else if (resource === 'customer') {
        axios({
          method: 'PUT',
          url: `${constants.API_URL}/customer/tasks/${removed.uuid}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            ...removed,
            assignee: removed.taskAssignee,
            due_date: constants.formatDate(removed.due_date),
            task_category: task_category_slug,
            status: destination.droppableId.replace(/[\[\]']+/g, ''),
          },
        })
          .then((response) => {
            if (response.status === 200) {
              let data = response.data;
              console.log('response data :: ', data);

              const _tasks = data.data.tasks;

              let obj = {};

              Object.keys(statuses).forEach((sta) => {
                let _tasksObj = [];

                _tasks.forEach((task) => {
                  if (task.status === sta) {
                    _tasksObj.push({
                      ...task,
                      id: task.id.toString(),
                    });
                  }
                });

                obj[`[${sta}]`] = {
                  name: statuses[sta],
                  items: _tasksObj,
                };
              });

              setColumns(obj);
              notify('success', 'Success', response.data.message);
            }
          })
          .catch((error) => {
            notify(
                'error',
                'Error',
                error.response && error.response.statusText
                    ? error.response.statusText
                    : 'Error occur.'
            );
            console.error('error :: ', error.response);
            if (error.response && error.response.status === 422) {
              return;
            }

          });
      }

      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      if (destination.index !== source.index) {
        updateTask({
          id: removed.id,
          status: destination.droppableId.replace(/[[\]']+/g, ''),
          index: destination.index,
          type: activeTab
        });
      }
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  const openTask = (id) => {
    setSelectedTask(id);

    toggleDetailsModal(true);
  };

  const toggleDetailsModal = (visible) => {
    setTaskModal({
      ...taskModal,
      show: visible,
      header: '',
    });
    if (visible === false) {
      setDescriptionEditMode(false);
      setTitleEditMode(false);
    }
  };

  function setEditMode(type, visibility) {
    switch (type) {
      case 'description':
        setDescriptionEditMode(visibility);
        break;
      case 'title':
        setTitleEditMode(visibility);
        break;
      default:
        break;
    }
  }

  function updateTask(data) {
    setLoading(true);
    dispatch({
      type: taskActions.UPDATE_TASK_REQUEST,
      data,
    });
  }

  const setTab = (e) => {
    fetchData({ type: parseToKey(e),'account_manager_id':selectedAccountManager })
    setActiveTab(e)
  }
  return (
    <>
      <CardsHeader
        name=""
        parentName=""
        currentName=""
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col lg={5} sm={8}>
                    <h1 className="mb-0">Tasks</h1>
                  </Col>

                  <Col lg={4}>
                    <ArraySelect
                        label={'Account Manager'}
                        options={managers}
                        value={selectedAccountManager}
                        onChange={(e) => {
                          setSelectedAccountManager(e.target.value)
                           fetchData({ type: activeTab,'account_manager_id': e.target.value });
                          }
                        }
                        defaultOption={true}
                        valueKey={`id`}
                        labelKey={`name`}
                        errorMessage={'Account Manager'}
                    />
                  </Col>
                </Row>
                <CustomTabs tabs={tabs} fullWidth activeTab={activeTab} setActiveTab={setTab} />
              </CardHeader>
              <CardBody style={{ display: 'flex' }}>
                <div
                  style={{
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    flexDirection:
                      window.innerWidth <= mobileSize ? 'column' : 'row',
                  }}
                >
                  {loading ? (
                    <ClipLoader
                      css={override}
                      size={40}
                      color={`#7B61E4`}
                      loading={loading}
                    />
                  ) : (
                    <DragDropContext
                      onDragEnd={(result) => {
                        onDragEnd(result, columns, setColumns);
                      }}
                    >
                      {Object.entries(columns).map(
                        ([columnId, column], index) => {
                          return (
                            <Card
                              style={{
                                width:
                                  window.innerWidth <= mobileSize
                                    ? `calc(100%)`
                                    : `calc(100% / ${columnsLength})`,
                                marginRight: 20,
                              }}
                              key={index}
                            >
                              <CardHeader>{column.name}</CardHeader>
                              <CardBody>
                                <Droppable
                                  droppableId={columnId}
                                  key={columnId}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={{
                                          background: snapshot.isDraggingOver
                                            ? 'lightblue'
                                            : 'white',
                                          minHeight: highestColumnItems * 90,
                                        }}
                                      >
                                        {column.items.map((item, index) => {
                                          return (
                                            <Draggable
                                              key={item.id}
                                              draggableId={item.id}
                                              index={index}
                                            >
                                              {(provided, snapshot) => {
                                                return (
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                      userSelect: 'none',
                                                      padding: 16,
                                                      margin: '0 0 8px 0',
                                                      minHeight: '80px',
                                                      backgroundColor: snapshot.isDragging
                                                        ? '#263B4A'
                                                        : '#ffffff',
                                                      color: '#525f7f',
                                                      boxShadow:
                                                        '0 0 2rem 0 rgb(136 152 170 / 15%)',
                                                      borderRadius: '0.375rem',
                                                      ...provided.draggableProps
                                                        .style,
                                                    }}
                                                    onClick={() =>
                                                      openTask(item.id)
                                                    }
                                                  >
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                          'space-between',
                                                      }}
                                                    >
                                                      <div>{item.title}</div>
                                                    </div>
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        justifyContent: 'end',
                                                        marginTop: 10,
                                                        textTransform:
                                                          'capitalize',
                                                      }}
                                                    >
                                                      {item.taskable_type.replace(
                                                        'App\\Models\\',
                                                        ''
                                                      ) +
                                                        ' # ' +
                                                        item.taskable_id}
                                                    </div>
                                                  </div>
                                                );
                                              }}
                                            </Draggable>
                                          );
                                        })}
                                        {provided.placeholder}
                                      </div>
                                    );
                                  }}
                                </Droppable>
                              </CardBody>
                            </Card>
                          );
                        }
                      )}
                    </DragDropContext>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {taskModal.show && (
          <DetailsModal
            isVisible={taskModal.show}
            toggleModal={toggleDetailsModal}
            descriptionEditMode={descriptionEditMode}
            titleEditMode={titleEditMode}
            statuses={constants.taskStatuses}
            customDealerMembers={customDealerMembers}
            setEditMode={setEditMode}
            taskId={selectedTask}
            tasks={taskState.tasks}
            inputDescriptionEl={inputDescriptionEl}
            inputTitleEl={inputTitleEl}
            updateTask={updateTask}
            taskEdit={taskEdit}
          />
        )}
      </Container>
    </>
  );
}
